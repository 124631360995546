var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissions.includes('sales-order-inquiry'))?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card-actions',{attrs:{"title":"Filter","collapsed":true,"action-collapse":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Search","label-for":"search"}},[_c('b-form-input',{attrs:{"id":"search","placeholder":"Code / Customer"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Start Date","label-for":"start_date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start_date","config":{ dateFormat: 'd-m-Y' },"placeholder":"Start Date"},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"End Date","label-for":"end_date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end_date","config":{ dateFormat: 'd-m-Y' },"placeholder":"End Date"},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"my-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getInquiry(
                  1,
                  _vm.filter.search,
                  _vm.filter.start_date,
                  _vm.filter.end_date
                )}}},[_vm._v(" Search ")])],1)],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right mb-1",attrs:{"variant":"success","pill":""},on:{"click":_vm.exportExcel}},[_vm._v(" Export to Excel ")])],1)],1),(_vm.permissions.includes('sales-order-inquiry'))?_c('b-card',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'gross_price')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.formatRupiah(props.row.gross_price)))])]):(props.column.field === 'created_at')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.moment(props.row.created_at)))])]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[(props.row.status === 0)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.status_name))]):(props.row.status === 1)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.status_name))]):_vm._e()])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.permissions.includes('sales-order-detail'))?_c('b-dropdown-item',{attrs:{"to":{ path: '/sales/order/' + props.row.id }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Detail")])],1):_vm._e(),(
                  _vm.permissions.includes('sales-order-delete') &&
                  props.row.status === 0
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteOrder(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2069866849)},[_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing "+_vm._s(_vm.meta.from)+" to "+_vm._s(_vm.meta.to)+" ")]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(_vm.meta.total)+" entries ")])]),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.meta.total,"per-page":_vm.meta.per_page,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.getInquiry(
                    value,
                    _vm.filter.search,
                    _vm.filter.start_date,
                    _vm.filter.end_date
                  ); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388)})],1)])])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }