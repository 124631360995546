import Vue from 'vue'

// axios
import axios from 'axios'
import { $themeConfig } from "@themeConfig";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: $themeConfig.app.apiUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
