<template>
  <div>
    <b-row v-if="permissions.includes('sales-order-inquiry')">
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col md="6">
              <b-form-group label="Search" label-for="search">
                <b-form-input
                  id="search"
                  placeholder="Code / Customer"
                  v-model="filter.search"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Start Date" label-for="start_date">
                <flat-pickr
                  id="start_date"
                  v-model="filter.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Start Date"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="End Date" label-for="end_date">
                <flat-pickr
                  id="end_date"
                  v-model="filter.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="End Date"
                />
                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="my-1"
                @click="
                  getInquiry(
                    1,
                    filter.search,
                    filter.start_date,
                    filter.end_date
                  )
                "
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="success"
          class="float-right mb-1"
          pill
          @click="exportExcel"
        >
          Export to Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card v-if="permissions.includes('sales-order-inquiry')">
      <!-- search input -->
      <!-- <b-row>
                <b-col class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </div>
                    </b-form-group>
                </b-col>
            </b-row> -->

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Gross Price -->
          <span
            v-else-if="props.column.field === 'gross_price'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{
              formatRupiah(props.row.gross_price)
            }}</span>
          </span>

          <!-- Column: Created at -->
          <span
            v-else-if="props.column.field === 'created_at'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ moment(props.row.created_at) }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'" class="text-nowrap">
            <b-badge :variant="statusVariant(props.row.status)">
              <span class="text-nowrap" v-if="props.row.status === 0">{{
                props.row.status_name
              }}</span>
              <span class="text-nowrap" v-else-if="props.row.status === 1">{{
                props.row.status_name
              }}</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/sales/order/' + props.row.id }"
                  v-if="permissions.includes('sales-order-detail')"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteOrder(props.row.id)"
                  v-if="
                    permissions.includes('sales-order-delete') &&
                    props.row.status === 0
                  "
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ meta.from }} to {{ meta.to }}
              </span>
              <span class="text-nowrap"
                >&nbsp;of {{ meta.total }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(
                      value,
                      filter.search,
                      filter.start_date,
                      filter.end_date
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BAlert,
  VBModal,
  BForm,
  BFormFile,
  BModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Code",
          field: "code",
          width: "260px",
        },
        {
          label: "Customer Name",
          field: "customer_name",
        },
        {
          label: "Cashier Name",
          field: "sales.name",
        },
        {
          label: "Total",
          field: "gross_price",
          width: "170px",
        },
        {
          label: "Date",
          field: "created_at",
          width: "120px",
        },
        {
          label: "Status",
          field: "status",
          width: "100px",
        },
        {
          label: "Action",
          field: "action",
          width: "100px",
        },
      ],
      filter: {
        search: null,
        start_date: null,
        end_date: null,
      },
      id: null,
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      search: "",
      rows: [],
      searchTerm: "",
      errors: "",
      errMessage: "",
      file: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-success",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getInquiry(1, "", "", "");
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    moment(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry($page, $search, $start_date, $end_date) {
      this.$http
        .get(
          "sales/orders?page=" +
            $page +
            ($search ? "&search=" + this.filter.search : "") +
            ($start_date ? "&start_date=" + this.filter.start_date : "") +
            ($end_date ? "&end_date=" + this.filter.end_date : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteOrder($id) {
      this.$http
        .post("sales/order/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Order has been deleted",
              variant: "danger",
            },
          });
          location.href = "/sales/order";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
    trigger($id) {
      this.id = $id;
    },
    exportExcel() {
      var $url = $themeConfig.app.apiUrl + "sales/orders/export";

      if (this.filter.search != null) {
        $url += "?search" + this.filter.search;

        if (this.filter.start_date != null && this.filter.end_date != null) {
          $url +=
            "&start_date=" +
            this.filter.start_date +
            "&end_date=" +
            this.filter.end_date;
        }
      }

      if (this.filter.start_date != null && this.filter.end_date != null) {
        $url +=
          "?start_date=" +
          this.filter.start_date +
          "&end_date=" +
          this.filter.end_date;
      }

      axios({
        url: $url,
        // timeout: 1000,
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName),
        },
      }).then((res) => {
        const current = new Date();
        const date = `${current.getDate()}/${
          current.getMonth() + 1
        }/${current.getFullYear()}`;
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", date + "_order_report.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>